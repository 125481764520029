import dynamic from 'next/dynamic';

import { ApiSettings } from '@hultafors/shared/types';

import { ParttrapApi } from '@hultafors/toeguard/api';
import { GlobalFields } from '@hultafors/toeguard/types';

import { FavoritesProvider } from '../favorites-context/favorites-context';
import { GlobalProvider } from '../global-context/global-context';

const SWRProvider = dynamic(() =>
  import('@hultafors/shared/context').then((module) => module.SWRProvider),
);

export interface ApplicationProviderProps {
  children?: React.ReactNode;
  value: GlobalFields;
  settings: ApiSettings;
  parttrapApi: ParttrapApi;
}

export const ApplicationProvider: React.FC<ApplicationProviderProps> = ({
  children,
  value: { ...value },
  settings,
  parttrapApi,
}) => {
  return (
    <SWRProvider>
      <GlobalProvider
        value={value}
        settings={settings}
        parttrapApi={parttrapApi}
      >
        <FavoritesProvider>{children}</FavoritesProvider>
      </GlobalProvider>
    </SWRProvider>
  );
};
