/**
 * @desc Transforms product to minimal representation needed for favorites and cart
 * @param object product
 * @return object (product representation)
 */
export const transformProduct = (product: any, modelSku = '') => {
  const productId = product.productId
    ? parseInt(`${product.productId}`)
    : parseInt(`${product.id}`);

  let image;
  if (product.images) {
    if (product.images.length > 0) {
      image = product.images[0];
    }
  } else {
    image = product.image;
  }

  let icons = [];

  if (product.features) {
    // detail page
    product.features.map((item: any) => {
      icons.push(item);
    });
  } else {
    icons = product.icons;
  }

  return {
    modelSku,
    sku: product.sku,
    productId: productId,
    name: product.name,
    image: image,
    parentCategory: product.parentCategory,
    category: product.category,
    price: product.price,
    usp: product.usp,
    isNew: product.isNew,
    icons: icons ? icons : [],
  };
};
